import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import cross_icon from "../../../assets/images/Cross_icon.svg";
//import GoogleIcon from "../../../assets/images/GoogleIcon.svg";
import { Rings } from "react-loader-spinner";
import {
  auth,
  //signInWithGoogle,
  signInWithUserEmailLink,
} from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.scss";
import { toast } from "react-toastify";
//import { SESSION_AUTH } from "utils/auth";

export function LoginPopup(props) {
  //const { UPDATE_USER_TOKEN } = SESSION_AUTH();
  const { handleLogin, dropOut } = props;
  //const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  const [user, loading] = useAuthState(auth);
  const [email, setEmail] = useState("");

  const isValidEmail = (email) => {
    const emailValidRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(emailValidRegex)) {
      return true;
    }
    return false;
  };
  const loginWIthEmail = async () => {
    if (email && isValidEmail(email)) {
      setLoader(true);
      const data = await signInWithUserEmailLink(email);

      if (data) {
        setLoginLinkMSG(data);
        toast.success(data);
      }
      setLoader(false);
    } else {
      toast("Enter your email address");
    }
  };
  // const loginWithGoogle = async () => {
  //   setLoader(true);
  //   const data = await signInWithGoogle();
  //   if (data) {
  //     handleLogin();
  //     UPDATE_USER_TOKEN(data.accessToken);
  //   }
  //   setLoader(false);
  // };
  return (
    <>
      <div
        align="center"
        direction="column"
        className="sixPromoPopup sixPromoPopup-sm userLogin"
        onClick={() => {
          if (dropOut) {
            handleLogin();
          }
        }}
      >
        <div
          className="sixPromoPopup-inner"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {loading || loader ? (
            <Rings
              height="80"
              width="80"
              color="#fff"
              radius="6"
              wrapperStyle={{}}
              wrapperClass="sixPromoLoader"
              visible={true}
              ariaLabel="rings-loading"
            />
          ) : null}
          <>
            <div className="sixPromoPopup-header">
              <div className="sixPromoPopup-header-heading">
                Sign up or log in
              </div>
              <div className="sixPromoPopup-header-desc">
                If you’ve already registered, this will log you in
              </div>
            </div>

            <div className="sixPromoPopup-close" onClick={handleLogin}>
              <img className="popup-cross_icon" src={cross_icon} alt="" />
            </div>

            <div className="sixPromoPopup-content">
              {/* <div className="userLogin-social">
                <button
                  onClick={loginWithGoogle}
                  type="button"
                  className="userLogin-social-btn"
                >
                  <img className="popup-cross_icon" src={GoogleIcon} alt="" />{" "}
                  Continue with Google
                </button>
              </div>
              <div className="userLogin-or">
                <span className="userLogin-or-text">or</span>
              </div> */}

              <div className="userLogin-email custom-form">
                {loginLinkMSG ? (
                  <>
                    <div className="userLogin-email-msg">{loginLinkMSG}</div>
                    <div
                      className="userLogin-email-link"
                      onClick={() => {
                        setLoginLinkMSG("");
                      }}
                    >
                      Did not get the link?
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      placeholder="your@email.com"
                      className="userLogin-email-input"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          if (!event.shiftKey) {
                            loginWIthEmail();
                          }
                        }
                      }}
                    />

                    <button
                      type="button"
                      className="userLogin-email-btn"
                      onClick={loginWIthEmail}
                      disabled={loader || loading}
                    >
                      Continue with Email
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
